import { Injectable } from "@angular/core";


@Injectable()
export class Globals {

  apiUrl: string = 'https://alta-server.borecki.usermd.net:8561/api/';
  hostname: string = 'https://alta-server.borecki.usermd.net:8561/';
  /*apiUrl: string = 'http://localhost:8561/api/';
  hostname: string = 'http://localhost:8561/';*/
}
